import React, { useState, useEffect } from 'react';
import * as Cookies from '../../platformui/Base/Cookies';
import { shape, string, func } from 'prop-types';
import classNames from 'classnames';

const InformationModal = ({ InformationModal, cookies, active }) => {
  const { CheckboxText, ButtonText, HeadingText, BodyText } = InformationModal;

  const [isVisible, setIsVisible] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    const cookieValue = Cookies.parseCookieData(
      'acceptedInformationModal',
      cookies
    );
    setIsVisible(cookieValue === null || cookieValue === 'false');
    setIsAccepted(cookieValue === 'true');
  }, [cookies]);

  const isInitialRender = React.useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    active(isVisible);
  }, [isVisible, active]);

  const closeInformationModal = () => {
    setIsVisible(false);
  };

  const handleCheckboxChange = () => {
    setIsAccepted(!isAccepted);
    updateCookie(!isAccepted);
  };

  const updateCookie = (value) => {
    Cookies.createCookie('acceptedInformationModal', value, 365);
  };

  return (
    isVisible && (
      <div className="information-modal-wrapper">
        <div className="shadow-bg" />
        <div className="information-modal" aria-modal="true" role="alertdialog">
          <div className="information-modal__content">
            {HeadingText && (
              <h1
                className="information-modal__content__title"
                dangerouslySetInnerHTML={{ __html: HeadingText }}
              />
            )}
            {BodyText && (
              <div
                className="information-modal__content__text"
                dangerouslySetInnerHTML={{ __html: BodyText }}
              />
            )}
            <div
              aria-label={CheckboxText}
              className="information-modal__content__checkbox"
              onClick={() => {
                handleCheckboxChange();
              }}
            >
              <button
                type="button"
                id="checkbox"
                className={classNames({
                  'information-modal__content__checkbox__button': true,
                  'checkbox--checked': isAccepted,
                })}
              />
              <span>{CheckboxText}</span>
            </div>
            <button
              className="information-modal__content__button"
              type="button"
              onClick={closeInformationModal}
            >
              {ButtonText}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

InformationModal.propTypes = {
  InformationModal: shape({
    CheckboxText: string,
    ButtonText: string,
    TitleText: string,
    BodyText: string,
  }),
  cookies: string,
  active: func,
};

export default InformationModal;
